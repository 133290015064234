import React, { useContext } from "react";
import SectionHeader from "../components/SectionHeader";
import { SettingsContext } from "../App";

const PrivacyPolicy = () => {
  const settings = useContext(SettingsContext);
  return (
    <div className="flex flex-col min-h-screen bg-white font-sans">
      <div className="container mx-auto px-4 py-8 sm:px-6 md:px-8 lg:px-12 xl:px-20 mt-20 sm:mt-28">
        <div>
          <SectionHeader
            headerInfo={{
              title: "Privacy Policy",
              subtitle: "Please Read It Carefully!",
            }}
          />
        </div>
        <div className="prose mx-auto text-gray-700" dangerouslySetInnerHTML={{ __html: settings?.Privacy }}></div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
