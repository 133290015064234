import React from "react";
import { Link } from "react-router-dom";
import BlogData from "../Blog/blogData";

const RelatedPost = () => {
  return (
    <div className="animate_top rounded-md border border-stroke bg-white p-9 shadow-solid-13  mr-6">
      <h4 className="mb-4 text-2xl font-semibold text-black ">Related Posts</h4>

      <div className="space-y-7.5">
        {BlogData.slice(0, 3).map((post, index) => (
          <div className="flex items-start gap-4 xl:gap-6" key={index}>
            <div className="relative h-24 w-24 flex-shrink-0 p-1">
              {" "}
              {/* Added padding here */}
              <img
                src={post.mainImage || "https://via.placeholder.com/150?text=No+Image"}
                alt={post.title || "Blog image"}
                className="object-cover rounded-md w-full h-full"
                onError={(e) => {
                  e.currentTarget.src = "https://via.placeholder.com/150?text=No+Image";
                }}
              />
            </div>
            <div className="flex-1">
              <h5 className="text-md font-medium text-black transition-all duration-300 hover:text-primary  dark:hover:text-primary">
                <Link to={`/blog/${post.id}`}>{post.title.slice(0, 40)}</Link>
              </h5>
              <p className="text-sm text-gray-600  mt-1">{post.author || "Unknown Author"}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedPost;
