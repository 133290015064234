import React from "react";

const SingleBrand = ({ brand }) => {
  const { image, href, name, imageLight } = brand;

  return (
    <a href={href} className="block h-10 w-[98px] relative">
      {/* <img className="opacity-65 transition-opacity duration-300 hover:opacity-100 dark:hidden" src={image} alt={name} /> */}
      <img className=" opacity-100 transition-opacity" src={imageLight} alt={name} />
    </a>
  );
};

export default SingleBrand;
