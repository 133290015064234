import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const Features = () => {
  return (
    <section className="w-full bg-gray-100 py-6">
      <div className="max-w-screen-lg mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-black uppercase text-sm font-inter font-bold tracking-widest -mb-6">FEATURES</h2>
          <h1 className="text-purple-600 uppercase text-xl font-inter font-bold tracking-widest">
            <br />
            A Proxy Service You Can Trust
          </h1>
        </div>
        <div className="flex flex-wrap justify-center">
          <div className="w-full sm:w-2/3 lg:w-1/3 mb-6 px-4">
            <div className="bg-white shadow-lg rounded-xl p-6 transition-transform transform hover:scale-105 hover:shadow-2xl flex flex-col justify-center items-center h-40 lg:h-48">
              <div className="text-center mb-4">
                <p className="text-gray-900 text-xl font-semibold">
                  Regular Updates
                </p>
              </div>
              <p className="text-gray-600 text-base text-center">
              We understand that IPs can get banned quickly on websites, which is why we plan to implement regular IP pool refreshes.
              </p>
            </div>
          </div>
          <div className="w-full sm:w-2/3 lg:w-1/3 mb-6 px-4">
            <div className="bg-white shadow-lg rounded-xl p-6 transition-transform transform hover:scale-105 hover:shadow-2xl flex flex-col justify-center items-center h-40 lg:h-48">
              <div className="text-center mb-4">
                <p className="text-gray-900 text-xl font-semibold">
                  Secure Connection
                </p>
              </div>
              <p className="text-gray-600 text-base text-center">
                Enjoy our no-log policy and a safe browsing experience with encrypted data transmission and secure connections. 
              </p>
            </div>
          </div>
          <div className="w-full sm:w-2/3 lg:w-1/3 mb-6 px-4">
            <div className="bg-white shadow-lg rounded-xl p-6 transition-transform transform hover:scale-105 hover:shadow-2xl flex flex-col justify-center items-center h-40 lg:h-48">
              <div className="text-center mb-4">
                <p className="text-gray-900 text-xl font-semibold">
                24/7 Support
                </p>
              </div>
              <p className="text-gray-600 text-base text-center">
              Take advantage of our dedicated 24/7 support team, always available to help with anything you need!
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
      <Link to="/Feature">
        <button
          className="mb-4 inline-block rounded-full border-2 border-purple-200 bg-white px-6 py-2 hover:bg-black hover:text-white transition duration-700 hover:shadow-md"
        >
          Learn More
        </button>
      </Link>
    </div>
      </div>
      <div className='mb-4'></div>
    </section>
  );
};

export default Features;
