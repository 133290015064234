import React from "react";
import { motion } from "framer-motion";
import { GlobeAltIcon, ShieldCheckIcon, KeyIcon, CloudIcon, RocketLaunchIcon, UserGroupIcon } from "@heroicons/react/24/outline";

const iconComponents = {
  globe: <GlobeAltIcon className="w-8 h-8 text-gray-600 dark:text-gray-300" />,
  shield: <ShieldCheckIcon className="w-8 h-8 text-gray-600 dark:text-gray-300" />,
  key: <KeyIcon className="w-8 h-8 text-gray-600 dark:text-gray-300" />,
  cloud: <CloudIcon className="w-8 h-8 text-gray-600 dark:text-gray-300" />,
  rocket: <RocketLaunchIcon className="w-8 h-8 text-gray-600 dark:text-gray-300" />,
  users: <UserGroupIcon className="w-8 h-8 text-gray-600 dark:text-gray-300" />,
};

const SingleFeature = ({ feature }) => {
  const { icon, title, description } = feature;
  const IconComponent = iconComponents[icon];

  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      whileInView="visible"
      transition={{ duration: 0.6, ease: "easeOut" }}
      viewport={{ once: true }}
      className="flex flex-col items-center rounded-lg border border-purple-200 bg-white p-4 shadow-md hover:scale-105 dark:border-gray-700 dark:bg-gray-800 gap-4 mx-4 my-6 duration-300 hover:shadow-lg hover:bg-gray-100 dark:hover:bg-gray-900" // Added hover effects and duration
    >
      <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full bg-gray-100 dark:bg-gray-900">{IconComponent}</div>
      <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 text-center rounded-full border-2 border-purple-400  px-6 py-2">
        {title}
      </h3>
      <p className="text-gray-600 dark:text-gray-400 text-center ">{description}</p>
    </motion.div>
  );
};

export default SingleFeature;
