import React from "react";
import featuresData from "./featuresData";
import SingleFeature from "./Singlepage";
import SectionHeader from "../components/SectionHeader";
import { Helmet } from "react-helmet";

const FeaturePage = () => {
  return (
    <section id="features" className="py-32 lg:py-28 xl:py-32">
        <Helmet>
        <title>AlertProxies - View The Best Features of AlertProxies</title>
        <meta
          name="description"
          content="Explore our affordable proxy pricing plans for residential, datacenter, IPv6, and mobile IPs. Get unlimited bandwidth, high speeds, and secure authentication."
        />
        <meta
          name="keywords"
          content="proxy pricing, residential proxy, datacenter proxy, IPv6 proxy, mobile proxy, affordable proxies, unlimited bandwidth, proxy plans"
        />
        <meta name="author" content="AlertProxies" />
        <meta property="og:title" content="AlertProxies - View The Best Features of AlertProxies" />
        <meta
          property="og:description"
          content="Explore our range of proxy services at competitive prices. We offer residential, datacenter, IPv6, and mobile IP plans."
        />
        <meta property="og:url" content="https:/alertproxies.com/Features" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.alertproxies.com/favicon.ico" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AlertProxies - View The Best Features of AlertProxies" />
        <meta
          name="twitter:description"
          content="AlertProxies.com provides an advanced web data collection infrastructure engineered to avoid rate limiting. Our proprietary proxy network, comprising millions of IPs, guarantees the effectiveness and security of your operations."
        />
        <meta name="twitter:image" content="https://www.alertproxies.com/favicon.ico" />
      </Helmet>
      <div className="container mx-auto px-4 md:px-8 xl:px-0">
        <SectionHeader
          headerInfo={{
            title: "Why Choose Us?",
            subtitle: "Best Features of AlertProxies",
            description: `Discover the core features that set Solid apart. From intuitive design to powerful functionality, we provide everything you need for a seamless experience.`,
          }}
        />

        <div className="mt-12 grid gap-8 md:grid-cols-2 lg:mt-16 lg:grid-cols-3 xl:mt-20 xl:gap-10">
          {featuresData.map((feature) => (
            <SingleFeature key={feature.id} feature={feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturePage;