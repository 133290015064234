import React from "react";

const SectionHeader = ({ headerInfo }) => {
  const { title, subtitle, description } = headerInfo;

  return (
    <>
      {/* <!-- Section Title Start --> */}
      <div className="animate_top mx-auto text-center mt-4">
        <div className="mb-4 inline-block rounded-full border-2 border-purple-200 bg-white px-6 py-2">
          <span className="text-sectiontitle font-md text-black">{title}</span>
        </div>
        <h2 className="mx-auto mb-4 text-3xl font-semibold text-black  md:w-4/5 xl:w-1/2 xl:text-sectiontitle3">{subtitle}</h2>
        <p className="mx-auto md:w-4/5 lg:w-3/5 xl:w-[46%]">{description}</p>
      </div>
      {/* <!-- Section Title End --> */}
    </>
  );
};

export default SectionHeader;
