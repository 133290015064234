import { useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import logo from "../assets/logo.png"; // Adjust the path to your image file
import { Link } from "react-router-dom";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <header className="fixed top-5 left-0 right-0 z-30 mx-auto px-4 sm:px-6 bg-white shadow-lg rounded-2xl backdrop-blur-md border border-gray-200 w-full max-w-4xl">
      <div className="relative flex items-center justify-between py-1 md:py-1 mx-auto">
        {/* Logo */}

        <div className="flex items-center space-x-4">
          {/* Wrap the logo in a Link component */}
          <Link to="/">
            <img src={logo} alt="Logo" width={50} height={50} />
          </Link>
        </div>

        {/* Navigation for desktop */}
        <div className="hidden md:flex flex-1 justify-center">
          <div className="flex space-x-10 ">
            <a href="/Feature" className="text-gray-800 hover:text-purple-600 transition duration-300">
              Features
            </a>
            <Menu as="div" className="relative">
              <a href="/Pricing" className="text-gray-800 hover:text-purple-600 transition duration-300">
                Pricing
              </a>
            </Menu>
            <a href="/faq" className="text-gray-800 hover:text-purple-600 transition duration-300">
              FAQ
            </a>
            <Menu as="div" className="relative">
              <Menu.Button className="text-gray-800 hover:text-purple-600 flex items-center transition duration-300">
                Company
                <ChevronDownIcon className="w-5 h-5 ml-2" />
              </Menu.Button>
              <Transition
                as="div"
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-150"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right bg-white border border-gray-200 rounded-lg shadow-lg">
                  <Menu.Item>
                    <a href="/tos" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                      Terms Of Service
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a href="/privacy" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                      Privacy Policy
                    </a>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        {/* Buttons for desktop */}
        <div className="hidden md:flex gap-4 items-center">
          <a
            href={`${process.env.REACT_APP_FRONTEND_URL}/auth/login`}
            className="bg-purple-400 text-white shadow-sm hover:bg-black hover:text-white py-2 px-4 rounded-lg transition-all duration-500"
          >
            Login
          </a>
          <a
            href={`${process.env.REACT_APP_FRONTEND_URL}/auth/register`}
            className="bg-black text-gray-200 shadow-sm hover:bg-purple-400 hover:text-white py-2 px-4 rounded-lg transition-all duration-300"
          >
            Register
          </a>
        </div>

        {/* Mobile menu button */}
        <button onClick={toggleMobileMenu} className="md:hidden p-2 text-gray-800 hover:text-gray-600">
          {isMobileMenuOpen ? <XMarkIcon className="w-6 h-6" /> : <Bars3Icon className="w-6 h-6" />}
        </button>
      </div>

      {/* Mobile menu */}
      <Transition
        show={isMobileMenuOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-150"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="md:hidden absolute top-16 left-0 right-0 bg-white border border-gray-200 rounded-lg shadow-lg">
          <div className="flex flex-col items-center py-4">
            <a href="/Feature" className="text-gray-800 hover:text-gray-600 py-2">
              Feature
            </a>

            <a href="/Pricing" className="text-gray-800 hover:text-gray-600 py-2">
              Pricing
            </a>

            <a href="/faq" className="text-gray-800 hover:text-gray-600 py-2">
              FAQ
            </a>
            <Menu as="div" className="relative">
              <Menu.Button className="text-gray-800 hover:text-gray-600 flex items-center py-2">
                Company
                <ChevronDownIcon className="w-5 h-5 ml-2" />
              </Menu.Button>
              <Transition
                as="div"
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-150"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute w-full mt-2 bg-white border border-gray-200 rounded-lg shadow-lg">
                  <Menu.Item>
                    <a href="/Blog" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                      Blog
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a href="/tos" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                      Terms Of Service
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a href="/privacy" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                      Privacy Policy
                    </a>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
            <div className="flex gap-4 mt-4">
              <a
                href={`${process.env.REACT_APP_FRONTEND_URL}/auth/login`}
                className="bg-purple-400 text-white shadow-sm hover:bg-black hover:text-white py-2 px-4 rounded-lg transition-all duration-500"
              >
                Login
              </a>
              <a
                href={`${process.env.REACT_APP_FRONTEND_URL}/auth/register`}
                className="bg-black text-gray-200 shadow-sm hover:bg-purple-400 hover:text-white py-2 px-4 rounded-lg transition-all duration-300"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </Transition>
    </header>
  );
};

export default Header;
