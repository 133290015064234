import React, { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/solid"; // Import the check icon from Heroicons v2
import SectionHeader from "../components/SectionHeader";
import { Helmet } from "react-helmet";
import PricingComp from "./Pricing";

const Pricing = () => {
  useEffect(() => {
    document.title = "Pricing"; // Set the page title dynamically
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div>
      <Helmet>
        <title>Pricing - Affordable Proxies for Every Need</title>
        <meta
          name="description"
          content="Explore our affordable proxy pricing plans for residential, datacenter, IPv6, and mobile IPs. Get unlimited bandwidth, high speeds, and secure authentication."
        />
        <meta
          name="keywords"
          content="proxy pricing, residential proxy, datacenter proxy, IPv6 proxy, mobile proxy, affordable proxies, unlimited bandwidth, proxy plans"
        />
        <meta name="author" content="AlertProxies" />
        <meta property="og:title" content="Pricing - Affordable Proxies for Every Need" />
        <meta
          property="og:description"
          content="Explore our range of proxy services at competitive prices. We offer residential, datacenter, IPv6, and mobile IP plans."
        />
        <meta property="og:url" content="https://www.alertproxies.com/pricing" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.alertproxies.com/favicon.ico" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Pricing - Affordable Proxies for Every Need" />
        <meta
          name="twitter:description"
          content="Choose from a variety of proxy plans to suit your needs. Residential, datacenter, IPv6, and mobile proxies at affordable rates."
        />
        <meta name="twitter:image" content="https://www.alertproxies.com/favicon.ico" />
      </Helmet>

      <PricingComp up={true} />
    </div>
  );
};

export default Pricing;
