let settings_value = {
  Name: null,
  Title: null,
  Email: null,
  Description: null,
  TOS: null,
  Privacy: null,
  Copyright: null,
  Telegram: null,
  Discord: null,
  Twitter: null,
  Facebook: null,
  FAQ: null,
  BannerTitle: null,
  BannerBody: null,
  BannerStyle: null,
  BannerEnabled: null,
  ReferralPercentage: null,
  CoinbaseEnabled: null,
};

export const processSettingsToValues = async (settings) => {
  try {
    await settings.map((v, k) => {
      if (v.Value === "true" || v.Value === "false") {
        settings_value[v.Name] = v.Value === "true" ? true : false;
      } else {
        settings_value[v.Name] = v.Value;
      }
    });

    return settings_value;
  } catch (error) {
    console.log("error processing settigns");
  }
};
