import React, { useState, useEffect, useContext } from "react";
import "tailwindcss/tailwind.css"; // Ensure Tailwind CSS is imported
import SectionHeader from "../components/SectionHeader";
import Metadata from "../components/MetaData";
import { SettingsContext } from "../App";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const settings = useContext(SettingsContext);

  const [faq, setFaq] = useState([]);

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChatOpen = () => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    }
  };

  // Metadata information
  const metadata = {
    title: "AlertProxies FAQ - Frequently Asked Questions",
    description: "Find answers to common questions about our proxies, payments, trials, and more at AlertProxies.",
  };

  useEffect(() => {
    if (settings?.FAQ) {
      const res = JSON.parse(settings?.FAQ);
      setFaq(res);
    }
  }, [settings]);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Metadata Component */}
      <Metadata title={metadata.title} description={metadata.description} />

      <main className="flex-grow px-4 py-32">
        <SectionHeader
          headerInfo={{
            title: "FAQ",
            subtitle: "You got questions? We Got Answers!",
          }}
        />

        <section className="max-w-3xl mx-auto space-y-6">
          {faq?.map((faq, index) => (
            <div
              key={index}
              className={`transition-all duration-300 rounded-lg border ${
                activeIndex === index ? "border-purple-600" : "border-transparent"
              } bg-white shadow-md mt-12 hover:bg-pink-50 transition duration-300`}
            >
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full flex justify-between items-center p-4 text-left text-lg font-medium text-gray-700 focus:outline-none"
                aria-expanded={activeIndex === index}
                aria-controls={`faq-answer-${index}`}
              >
                <span>{faq.question}</span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-300 ${activeIndex === index ? "rotate-180" : "rotate-0"}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div id={`faq-answer-${index}`} className={`px-4 pt-2 pb-4 text-gray-700 ${activeIndex === index ? "block" : "hidden"}`}>
                {faq.answer}
              </div>
            </div>
          ))}
        </section>

        <section className="text-center mt-12">
          <button
            onClick={handleChatOpen}
            className="rounded-full border-2 border-purple-200 bg-white px-6 py-2 transition duration-500 ease-in-out hover:bg-pink-100 hover:text-gray-800"
          >
            Contact Support
          </button>
        </section>
      </main>
    </div>
  );
};

export default FAQ;
