import React, { useEffect } from 'react';

const Metadata = ({ title, description }) => {
  useEffect(() => {
    // Update the document title
    document.title = title;

    // Find the existing meta description or create a new one
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      // If it doesn't exist, create it and append it to the head
      metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      document.head.appendChild(metaDescription);
    }

    // Update the content of the meta description
    metaDescription.content = description;

    return () => {
      // Optional cleanup (but not needed in most cases unless you want to remove the tag)
      document.head.removeChild(metaDescription);
    };
  }, [title, description]);

  return null;
};

export default Metadata;