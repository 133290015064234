import React from "react";
import SingleBrand from "./SingleBrand";
import brandData from "./brandData";

const Brands = () => {
  return (
    <section className="border border-x-0 border-y-stroke bg-gray-100 py-11 dark:border-y-strokedark ">
      <div className="flex justify-center">
        <div className="max-w-[1390px] px-4 md:px-8 2xl:px-0">
          {/* Update the grid layout to have 2 columns on mobile */}
          <div className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 lg:gap-10 xl:gap-20">
            {brandData.map((brand, index) => (
              <SingleBrand brand={brand} key={index} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
