import brandLight01 from "../assets/brand/brand-light-01.svg";
import brandLight02 from "../assets/brand/brand-light-02.svg";
import brandLight03 from "../assets/brand/brand-light-03.svg";
import brandLight04 from "../assets/brand/brand-light-04.svg";
import brandLight05 from "../assets/brand/brand-light-05.svg";
import brandLight06 from "../assets/brand/brand-light-06.svg";

import brandDark01 from "../assets/brand/brand-dark-01.svg";
import brandDark02 from "../assets/brand/brand-dark-02.svg";
import brandDark03 from "../assets/brand/brand-dark-03.svg";
import brandDark04 from "../assets/brand/brand-dark-04.svg";
import brandDark05 from "../assets/brand/brand-dark-05.svg";
import brandDark06 from "../assets/brand/brand-dark-06.svg";

import Reddit from "../assets/brand/Reddit.png"
import Discord from "../assets/brand/Discord.png"
import Github from "../assets/brand/Github.png"
import Meta from "../assets/brand/Meta.png"
import BBC from "../assets/brand/BBC.png"

const brandData = [
  {
    id: 0.25,
    name: "Client",
    href: "#",
    image: Reddit,
    imageLight: Reddit,
  },
  {
    id: 0.3,
    name: "Client",
    href: "#",
    image: Discord,
    imageLight: Discord,
  },
  {
    id: 0.4,
    name: "Client",
    href: "#",
    image: Github,
    imageLight: Github,
  },
  {
    id: 0.5,
    name: "Client",
    href: "#",
    image: Meta,
    imageLight: Meta,
  },
  {
    id: 0.6,
    name: "Client",
    href: "#",
    image: BBC,
    imageLight: BBC,
  },
];

export default brandData;
