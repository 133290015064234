import { GlobeAltIcon, ShieldCheckIcon, KeyIcon, CloudIcon, RocketLaunchIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const featuresData = [
  {
    id: 1,
    icon: 'globe',
    title: 'Global Coverage',
    description: 'Access proxies from around the world to ensure optimal performance.',
  },
  {
    id: 2,
    icon: 'shield',
    title: 'Secure and Private',
    description: 'Enjoy secure and anonymous browsing with our encrypted proxies.',
  },
  {
    id: 3,
    icon: 'key',
    title: 'Easy Access',
    description: 'Seamless integration and simple authentication for easy access.',
  },
  {
    id: 4,
    icon: 'cloud',
    title: 'Cloud-Based',
    description: 'Scalable and reliable proxy solutions hosted in the cloud.',
  },
  {
    id: 5,
    icon: 'rocket',
    title: 'High-Speed',
    description: 'Experience lightning-fast speeds and minimal latency with our proxies.',
  },
  {
    id: 6,
    icon: 'users',
    title: 'User-Friendly',
    description: 'Intuitive and easy-to-use interface designed for everyone.',
  },
];

export default featuresData;