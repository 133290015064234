import React, { useContext } from "react";
import SectionHeader from "../components/SectionHeader";
import { SettingsContext } from "../App";
const TOS = () => {
  const settings = useContext(SettingsContext);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="container mx-auto px-4 py-8 sm:px-6 md:px-8 lg:px-12 xl:px-20 mt-20 sm:mt-28">
        <div>
          <SectionHeader
            headerInfo={{
              title: "Terms Of Service",
              subtitle: "Please Read It Carefully!",
            }}
          />
        </div>

        <div className="prose mx-auto text-gray-700" dangerouslySetInnerHTML={{ __html: settings?.TOS }}></div>
      </div>
    </div>
  );
};

export default TOS;
